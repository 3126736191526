function Countdown (resolution, message, year, month, day, hour = 0, minutes = 0) {
  const resolutions = ['day', 'hour', 'minute', 'second']
  this.message = message
  this.target = new Date(year, month, day, hour, minutes)
  this.current = ''
  try {
    if (resolutions.includes(resolution)) {
      this.resolution = resolution
    } else {
      throw new Error('resolution scope of ' + resolution + 'does not exist')
    }
  } catch (e) {
    console.warn(e.message)
    this.resolution = 'minute'
  }

  try {
    if (document.getElementById('countdown')) {
      this.element = document.getElementById('countdown')
      this.container = document.createElement('div')
      this.container.classList.add('countdown__text')
      this.container.classList.add('not-ready')
      const timer = document.createElement('span')
      const mess = document.createElement('span')
      timer.classList.add('countdown__timer')
      mess.classList.add('countdown__message')
      mess.innerHTML = this.message
      this.container.appendChild(timer)
      this.container.appendChild(mess)
      this.element.appendChild(this.container)
      this.intervalID = setInterval(this.tick, 1000, this.target, this.resolution, timer)
      this.container.classList.remove('not-ready')
    } else {
      throw new Error('no target element found, not creating timer')
    }
  } catch (e) {
    console.error(e.message)
  }
}
Countdown.prototype.stop = function () {
  clearInterval(this.intervalID)
}

Countdown.prototype.tick = function (target, resolution, element) {
  const targetTimestamp = target.getTime()
  const now = new Date().getTime()

  const diff = targetTimestamp - now

  const days = Math.floor(diff / (1000 * 60 * 60 * 24))
  const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((diff % (1000 * 60)) / 1000)

  if (diff <= 0) {
    this.stop()
    element.remove()
    return
  }

  let inner = ''

  if (days !== 0) {
    const dtext = days === 1 ? 'Day ' : 'Days '
    inner += parseInt(days) + ' ' + dtext
  }

  if (resolution !== 'day' && hours !== 0) {
    inner += parseInt(hours) + 'h '
  };

  if (resolution !== 'day' && resolution !== 'hour' && minutes !== 0) {
    inner += parseInt(minutes) + 'm '
  };

  if (resolution === 'second') {
    inner += parseInt(seconds) + 's '
  };

  if (inner === this.current) {
    return
  } else {
    this.current = inner
    element.innerText = inner
  }
}
